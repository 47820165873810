<template>
  <div class="login">
    <div class="mainBox">
      <!-- 头部 -->
      <el-row>
        <el-col :span="24">
          <div class="nameBox">
            <div class="cuser">运维邦</div>
            <div>|</div>
            <div class="cuser">中亮科技</div>
          </div>
        </el-col>
      </el-row>
      <div class="loginBox">
        <div class="loginCenterBox">
          <div class="logoBox">
            <img
              src="@/assets/images/zllogo.png"
              style="width: 60px; height: 60px"
            />
            <div class="logoName">
              <div class="topName">运维邦·中亮</div>
              <div class="f13 mt5">用心服务，客户至上</div>
            </div>
          </div>
          <div class="inputMainBox">
            <div class="inputBox">
              <!-- <div class="inputItem">
                          <img src="" alt=""> -->
              <el-autocomplete
                type="text"
                v-model="username"
                class="input"
                :fetch-suggestions="querySearch"
                @select="handleSelect"
                placeholder="请输入用户名称"
              >
                <i slot="prefix" class="el-input__icon el-icon-user"></i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.username }}</div>
                  <span class="addr">......</span>
                </template>
              </el-autocomplete>
              <!-- </div>
                      <div class="inputItem">
                          <img src="" alt=""> -->
              <el-input
                type="passsword"
                show-password
                v-model="password"
                @keyup.native.enter="enterSubmit"
                class="input ml10"
                placeholder="请输入登录密码"
              >
                <i slot="prefix" class="el-input__icon el-icon-lock"></i>
              </el-input>
              <!-- </div> -->
            </div>
            <div class="circleBox">
              <i
                class="el-icon-right marginAuto"
                v-if="!isLogin"
                @click="submit"
              ></i>
              <i class="el-icon-loading marginAuto" v-if="isLogin"></i>
            </div>
          </div>
        </div>
      </div>
      <!--备案号-->
      <div class="footer">
        <div>2024 &copy; Copyright. All Rights Reserved</div>
        <div>中亮科技有限公司版权所有</div>
        <div>皖ICP备2022003155号</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserMenu } from "@/utils/api/institution/menu.js";
export default {
  data() {
    return {
      isLogin: false,
      username: "",
      password: "",
      code: "",
      userPassWordList: [],
      restaurants: [],
      state1: "",
      state2: ""
    };
  },
  created() {},
  mounted() {
    if (JSON.parse(localStorage.getItem("userPassWord"))) {
      this.username = JSON.parse(localStorage.getItem("userPassWord"))[
        JSON.parse(localStorage.getItem("userPassWord")).length - 1
      ].username;
      this.password = JSON.parse(localStorage.getItem("userPassWord"))[
        JSON.parse(localStorage.getItem("userPassWord")).length - 1
      ].password;
    }
  },
  methods: {
    querySearch(queryString, cb) {
      if (!queryString) {
        this.userPassWordList = JSON.parse(localStorage.getItem("userPassWord"))
          ? JSON.parse(localStorage.getItem("userPassWord"))
          : [];
        this.restaurants = this.userPassWordList;
      }
      let restaurants = this.restaurants;
      let results = queryString
        ? restaurants.filter(item => {
            return item.username.match(queryString);
          })
        : // ? restaurants.filter(this.createFilter(queryString))
          restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelect(item) {
      (this.username = item.username), (this.password = item.password);
    },
    // 登录前存储账号
    storeAccountNumber() {
      let userPassWordList = JSON.parse(localStorage.getItem("userPassWord"))
        ? JSON.parse(localStorage.getItem("userPassWord"))
        : [];
      let flag = userPassWordList.findIndex(item => {
        return item.username == this.username;
      });
      if (flag == -1) {
        userPassWordList.push({
          username: this.username,
          password: this.password
        });
      }
      localStorage.setItem("userPassWord", JSON.stringify(userPassWordList));
    },
    // 登录
    submit() {
      var that = this;
      if (!that.username) {
        that.$message({
          message: "请输入用户名",
          type: "warning"
        });
        return;
      }
      if (!that.password) {
        that.$message({
          message: "请输入密码",
          type: "warning"
        });
        return;
      }
      console.log(that.username);
      console.log(that.$md5(that.password));

      //登陆到首页
      that.$http
        .post("/userInfo/login", {
          loginName: that.username,
          password: that.$md5(that.password)
        })
        .then(async function(response) {
          if (response.data.code == 200) {
            that.storeAccountNumber();
            that.setLocalStorage("token", response.data.code, 30 * 60 * 1000);
            console.log(response.data.data.userInfo.userCode, 116);
            that.getAuthInfo(response.data.data.userInfo.userCode);

            localStorage.setItem("loginName", that.username, 30 * 60 * 1000);

            localStorage.setItem(
              "userInfo",
              JSON.stringify(response.data.data.userInfo)
            );
            localStorage.setItem("authToken", response.data.data.token);
            console.log(response.data.data, 126);

            localStorage.setItem(
              "stationCodeList",
              JSON.stringify(response.data.data.stationCodeList)
            );
            that.username = "";
            that.password = "";
            await that.getMenuList();
            that.$router.push({ path: "/map" });
          } else {
            that.$message({
              message: response.data.message,
              type: "warning"
            });
          }
        });
    },
    // 获取请求菜单
    async getMenuList() {
      var that = this;
      var loginName = localStorage.getItem("loginName");
      //存储超时，跳转到登陆页
      if (loginName == null || loginName == "") {
        //用户名不存在跳转登陆
        that.$router.push({ path: "/login" });
        return;
      }
      const res = await getUserMenu({
        userCode: JSON.parse(localStorage.getItem("userInfo")).userCode
      });
      this.menus = res.data.data;
      this.menus.some(item => {
        if (item.link == "/config") {
          item.childrens[0].childrens.push({
            authCode: "",
            childrens: [],
            creator: "",
            description: "",
            iconUrl: "",
            id: Math.floor(Math.random() * 1e8),
            link: "/config/config/alarmManage",
            menuCode: "33",
            menuName: "告警管理",
            menuOrder: 17,
            menuType: 1,
            parentCode: item.menuCode,
            seq: 0,
            userCode: "",
            visible: null
          });
          return true;
        }
      });
      console.log("menu", this.menus);
      this.$store.commit("createMenus", this.menus);
      // this.$http
      //   .post("/menuInfo/getUserMenu", {
      //     loginName: loginName,
      //   })
      //   .then(function (res) {
      //     console.log("index menu", res.data.data);
      //     that.menuList = res.data.data;
      //     that.currFirstItem = that.menuList.length ? that.menuList[0] : {};
      //     that.showLoading = false;
      //   });
    },
    // 回车事件
    enterSubmit() {
      this.submit();
    },

    async getAuthInfo(userCode) {
      let res = await this.$http.post("engineeringMenu/getMenuAuthList", {
        userCode
      });
      console.log(res, "getAuthInfogetAuthInfo");
      if (res.data.code == 200) {
        localStorage.setItem("authList", JSON.stringify(res.data.data));
      } else {
        this.$message.error(res.data.message);
      }
    },

    setLocalStorage(key, value, expires) {
      // 保持到LocalStorage,expires单位是毫秒
      // expires = expires * 1000;
      let params = { key, value, expires };
      if (expires) {
        // 记录何时将值存入缓存，毫秒级
        var data = Object.assign(params, { startTime: new Date().getTime() });
        localStorage.setItem(key, JSON.stringify(data));
      } else {
        if (Object.prototype.toString.call(value) == "[object Object]") {
          value = JSON.stringify(value);
        }
        if (Object.prototype.toString.call(value) == "[object Array]") {
          value = JSON.stringify(value);
        }
        localStorage.setItem(key, value);
      }
    }
  },
  created() {}
};
</script>

<style lang="less" scoped>
body .el-notification {
  background-color: rgba(139, 88, 92, 0.5);
  border: transparent;
}
.el-notification__title,
.el-notification__content > p {
  color: #fff;
}
h2.el-notification__title {
  font-size: 16px;
  font-weight: 400;
}
.header-left {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 100;
}
.header-right {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  border: 1px solid #fff;
  border-radius: 20px;
  padding: 5px 20px;
}
.main_box {
  height: calc(100vh - 70px);
  height: -webkit-calc(100vh - 70px);
  height: -moz-calc(100vh - 70px);
}

.header .text {
  padding: 0 10px;
  border-left: 1px solid #999;
}
.blue {
  color: #409eff;
}
.login {
  background: url("../assets/images/bigBg.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}
.mainBox {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.login_form {
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  left: 50%;
  background-color: #ffffff;
  width: 50%;
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding-bottom: 50px;
}
.login_form .form_tit {
  margin-top: 80px;
  color: #409eff;
  font-size: 30px;
  font-weight: bold;
}
.login_form .form_group {
  width: 80%;
  margin: 18px auto 0 auto;
  border-bottom: 1px solid #f2f2f2;
}
.login_form .form_group1 {
  width: 80%;
  margin: 18px auto 0 auto;
}
.login_form input {
  height: 42px;
  border: none;
  outline: 0;
  padding-left: 15px;
  font-size: 12px;
  box-sizing: border-box;
  outline: none;
}
.login_form input ::placeholder {
  color: #333 !important;
}
.login_btn {
  height: 45px;
  background: #409eff;
  border-radius: 5px;
  width: 65%;
  transition: all 0.3s;
  margin: 0 auto;
  margin-top: 30px;
}
.login_btn button {
  width: 100%;
  height: 100%;
  color: #ffffff;
  background-color: transparent;
  outline: none;
  border: none;
}
.login_btn:hover {
  transform: translateY(-6px);
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.1);
}
.footer {
  position: absolute;
  bottom: 20px;
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 16px;
}
@media (max-width: 1440px) {
  .login_form .form_group {
    margin: 15px auto 0 auto;
  }
  .login_btn {
    margin-top: 20px;
  }
}
.nameBox {
  color: #fff;
  display: flex;
  margin-top: 30px;
  margin-left: 70px;
  width: 190px;
  font-size: 22px;
  justify-content: space-between;
}
.loginBox {
  width: 100%;
  height: 300px;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  backdrop-filter: blur(2px);
}
.loginCenterBox {
  width: 800px;
  margin-left: 50vw;
  transform: translateX(-50%);
}
.logoBox {
  display: flex;
  color: #fff;
  align-items: center;
  margin-top: 50px;
  margin-left: calc(50% - 10px);
  transform: translateX(-50%);
  justify-content: center;
}
.logoName {
  margin-left: 10px;
}
.topName {
  font-size: 30px;
  font-weight: 700;
}
.mt5 {
  margin-top: 5px;
}
.inputMainBox {
  width: 70%;
  display: flex;
  align-items: center;
  margin-top: 50px;
  position: relative;
  margin-left: calc(50% + 25px);
  transform: translateX(-50%);
}
.inputBox {
  display: flex;
  /* margin-top: 50px; */
  align-items: center;
  /* width: 100%; */
}
.inputItem {
  width: 200px;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
}
input {
  background: none;
  border: none;
  color: #eee;
}
input::-webkit-input-placeholder {
  color: #eee;
}
input::-moz-placeholder {
  color: #eee;
}

.input /deep/ .el-input__inner {
  width: 270px !important;
  height: 55px !important;
  border: 1px solid #555;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding-left: 65px;
  color: #eee;
}
.el-input__icon {
  font-size: 22px;
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.circleBox {
  width: 50px;
  height: 50px;
  border: 1px solid #555;
  border-radius: 50%;
  margin-left: 10px;
  color: #eee;
  align-items: center;
  font-size: 35px;
  background: rgba(255, 255, 255, 0.1);
  padding-left: 10px;
  padding-right: 10px;
}
.marginAuto {
  margin: 0 auto;
  cursor: pointer;
}
.illustrateBox {
  color: #eee;
  font-size: 15px;
  display: flex;
  align-items: center;
  margin-left: 40px;
  cursor: pointer;
}

.ml5 {
  margin-left: 5px;
}
#hoverBox {
  display: none;
  position: absolute;
  bottom: -150px;
  right: 0;
}
.hoverContent {
  width: 200px;
  background: rgba(255, 255, 255, 0.1);
  font-size: 15px;
  color: #eee;
  padding: 10px;
  text-align: center;
}
.hoverArrow {
  position: absolute;
  left: 65px;
  top: -15px;
  // border-top: 10px solid #ccc;
  border-bottom: 15px solid rgba(255, 255, 255, 0.1);
  border-right: 8px solid transparent;
  border-left: 8px solid transparent;
}
.cuser {
  cursor: pointer;
}
</style>
